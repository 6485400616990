import {
  ref, onMounted, getCurrentInstance, watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { deliveryManifestUseCase, reportUseCase } from '@/domain/usecase'
import moment from 'moment'
import { forEach } from 'lodash'
import { isMobile } from '@/plugins/system/device'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { fileWrite } from '@/plugins/system/filesystem'

export default {
  name: 'DeliveryManifest',
  setup() {
    const app = getCurrentInstance()
    const { $swal, $toast, $strInd } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const data = ref([])
    const inputSearch = ref('')
    const sortOp = ref()
    const dateOp = ref()
    const lastManifestExist = ref(false)
    const lastManifest = ref([])
    const lastManifestId = ref('')
    const lastNoManifest = ref('')
    const sortingOption = ref([
      {
        value: 'WaktuKeberangkatan desc',
        name: 'Waktu Keberangkatan'
      },
      {
        value: 'KodeJadwal desc',
        name: 'Jadwal'
      },
      {
        value: 'Pendapatan desc',
        name: 'Total Pendapatan Agen'
      }
    ])
    const sortBy = ref('WaktuKeberangkatan desc')
    const dateFilter = ref(null)
    const ritFilter = ref(null)
    const filters = {
      search: '',
      filter: '',
      sorting: 'TanggalAktif desc',
      date: moment().format('YYYY-MM-DD'),
      expand: '',
      totalRecords: 0,
      top: 0,
      skip: 0,
      rit: [] as any
    }
    const ritOption = [
      { name: 'RIT 1', value: 1 },
      { name: 'RIT 2', value: 2 }
    ]

    const getAllData = async (reset: any = false) => {
      store.dispatch('showLoading')
      const response = await deliveryManifestUseCase.getListManifestV2(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        data.value = response.result
        // console.log('data', response)
      }
      store.dispatch('hideLoading')
    }

    const searchData = (search: any) => {
      filters.search = search
      getAllData()
    }

    const showDateFilter = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const showSortFilter = (evt: any) => {
      sortOp.value.toggle(evt)
    }

    const changeSortBy = async (val: any) => {
      sortBy.value = await val
      filters.sorting = await val
      getAllData()
    }

    const saveFilterDate = () => {
      filters.date = moment(dateFilter.value).format('YYYY-MM-DD')
      getAllData()
      dateOp.value.hide()
    }

    const clearFilterDate = () => {
      dateFilter.value = null
      filters.date = moment().format('YYYY-MM-DD')
      getAllData()
      dateOp.value.hide()
    }

    const strDate = (date: any) => {
      const now = new Date() as any
      const dateFormat = new Date(date) as any
      const diff = Math.floor((dateFormat - now) / (1000 * 3600 * 24))
      let result = ''
      if (diff === 1) {
        result = `BESOK, ${moment(date).format('HH:mm')} WIB`
      } else if (diff === 0) {
        result = `HARI INI, ${moment(date).format('HH:mm')} WIB`
      } else if (diff > 0) {
        result = `${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      } else {
        result = `TERLAMBAT, ${moment(date).format('DD-MM-YYYY HH:mm')} WIB`
      }
      return result
    }

    const classDate = (date: any) => {
      const now = new Date() as any
      const dateFormat = new Date(date) as any
      const diff = Math.floor((dateFormat - now) / (1000 * 3600 * 24))
      if (diff < 0) {
        return 'color-red'
      }
      return 'color-green-darken-3'
    }

    // converter base64
    const toBase64 = (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

    const printResi = (val: any) => {
      console.log('val', val)
      // const encryptData = encryptDecriptMethods.encrypt(val.Id)
      const routeData = router.resolve({ name: 'report', params: { Id: val, jenisReport: 'manifest' } })
      window.open(routeData.href, '_blank')
    }

    const getLastmanifest = () => {
      if ('lastManifest' in localStorage) {
        // console.log(JSON.parse(localStorage.lastManifest).length)
        const lastManifestTemp = JSON.parse(localStorage.lastManifest)
        if (lastManifestTemp.length === 2) {
          lastManifest.value = lastManifestTemp
          lastManifestExist.value = true
        }
      }
    }

    const filtersv2 = {
      expand: '&$expand=TransPengirimanHds($select=AgenAsalId)',
      filter: '',
      orderby: '&$orderBy=CreatedDate desc'
    }
    const getLastManifestv2 = async () => {
      const AgenAsalId = store.getters['appActiveUser/getAgent']?.id
      filtersv2.filter = `&$filter=TransPengirimanHds/any(o:o/AgenAsalId eq ${AgenAsalId})`
      const response = await deliveryManifestUseCase.getLastManifest(filtersv2)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        console.log(response.result)
        if (response.result.length > 0) {
          // eslint-disable-next-line no-multi-assign
          lastManifest.value = response.result[0]?.Id
          const encryptData = encryptDecriptMethods.encrypt(response.result[0]?.Id)
          lastManifestId.value = encryptData
          console.log(lastManifestId)
          lastNoManifest.value = response.result[0]?.NoManifest
          lastManifestExist.value = true
        }
      }
      store.dispatch('hideLoading')
    }

    onMounted(() => {
      getAllData()
      getLastManifestv2()
      console.log('store', store)
    })

    watch([ritFilter], () => {
      if (ritFilter.value) {
        filters.rit = ritFilter.value
        getAllData()
      }
    })

    return {
      data,
      inputSearch,
      store,
      route,
      sortOp,
      dateOp,
      sortingOption,
      sortBy,
      dateFilter,
      ritOption,
      ritFilter,
      filters,
      changeSortBy,
      strDate,
      showDateFilter,
      showSortFilter,
      classDate,
      searchData,
      getAllData,
      saveFilterDate,
      clearFilterDate,
      moment,
      printResi,
      lastManifest,
      lastManifestExist,
      lastManifestId,
      lastNoManifest
    }
  }
}
